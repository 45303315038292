.App {
  text-align: center;
  
}


.App-header {
  background-color: lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: rgb(4, 4, 4);
  padding: 5px;
  height: 10px;
}

.App-Card{
  background-color: blue
 
}

