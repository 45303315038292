.Header{

    font-size: large;
    color: black;

}

.FormBody{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: gainsboro;
    flex-direction: column;

    
    margin: 30px;
    padding: 30px;
    font-size: 16px;

}


.Card{
    background-color: rgb(239, 11, 11);
}


